/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// import global stuff
import './src/scss/style.scss'
import Glide from '@glidejs/glide';
import { fixedHeaderToTop, showBackToTopButton, filterOptions, openModal, closeModal } from './src/js/main.js'

const initSlider = () => {
  const hasSlider = document.querySelector('.js-slider');
  const sliderLength = document.querySelectorAll('.slider__wrapper').length - 3;
  if (hasSlider) {
    const slider = new Glide('.js-slider', {
      rewind: false,
      perView: 3,
      gap: 0,
      breakpoints: {
        767: {
          perView: 1,
          focusAt: 'center',
          peek: { before: 5, after: 35 }
        }
      },
    });

    slider.on(['move'], function() {
      var back = document.getElementById('slider_back');
      var forward = document.getElementById('slider_forward');
      var widowsSize = window.innerWidth;

      if (widowsSize >= 768) {
        if (forward) {
          if(slider.index === sliderLength) {
            forward.classList.add('slider__custom-disabled');
            forward.disabled = true;
            slider.disable();
          } else {
            forward.classList.remove('slider__custom-disabled');
            forward.disabled = false;
          }
        }

        if (back) {
          back.addEventListener('click', () => {
            slider.enable();
          });
        }
      }
    })

    slider.mount();
  }
};

export const onRouteUpdate = ({ location }) => {
  const pathsToCheck = [
    '/about/', 
    '/en/about/', 
    '/public-offering/2023/trusted_web2023_koubo/', 
    '/public-offering/2023/trusted_web2023_diw/'
  ];

  if (pathsToCheck.includes(location.pathname)) {
    if (!location.hash) {
      window.scrollTo({top: 0, behavior: 'instant'});
    }

    fixedHeaderToTop();
    showBackToTopButton();
  } else {
    window.scrollTo(0, 0);
    fixedHeaderToTop();
    showBackToTopButton();
    filterOptions();
    initSlider();
  }

  openModal();
  closeModal();
};