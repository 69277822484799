const helpers = {
  /**
  * 1. Add background color to header when page is scrolled
  * @param {header} header .header
  * @returns {void}
  */
  addClassToHeader: header => {
    // No. 1
    if (document.documentElement.scrollTop > 0) {
      header.classList.add('header--scroll');
    } else {
      header.classList.remove('header--scroll');
    }
  },
  /**
  * 1. Displays the back to top button
  * 2. Make position of back to top button static when footerWrapper is visible
  * @param {backToTopButton} backToTopButton #backToTop
  * @param {footerWrapper} footerWrapper .footer__links-wrapper
  * @returns {void}
  */
  addClassToBackToTopButton: (backToTopButton, footerWrapper) => {
    const cookieBanner = document.querySelector('#cookies__wrapper');
    // get height of cookie banner if it is visible
    let cookieBannerHeight = cookieBanner ? cookieBanner.clientHeight : 0;
    const rect = footerWrapper.getBoundingClientRect();
    const elemTop = rect.top;
    // add height of cookie banner if it is visible
    const elemBottom = window.innerWidth > 767 ? rect.bottom + cookieBannerHeight : (rect.bottom + cookieBannerHeight) - 46;
    // Only completely visible elements return true
    const isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
    // No. 1
    if (document.documentElement.scrollTop > 50) {
      backToTopButton.classList.remove('back-to-top--hidden');
      backToTopButton.style.bottom = 'calc(2rem + ' + cookieBannerHeight + 'px)';
    } else {
      backToTopButton.classList.add('back-to-top--hidden');
    }
    // No. 2
    if (isVisible) {
      backToTopButton.classList.add('back-to-top--static');
      backToTopButton.removeAttribute('style');
    } else {
      if (document.documentElement.scrollTop < footerWrapper.offsetTop) {
        backToTopButton.classList.remove('back-to-top--static');
      }
    }
  }
};

const fixedHeaderToTop = () => {
  const header = document.querySelector('.header');

  if (header) {
    // on load
    helpers.addClassToHeader(header);
    // on scroll
    window.addEventListener('scroll', () => {
      helpers.addClassToHeader(header);
    });
  }
};

const showBackToTopButton = () => {
  const backToTopButton = document.querySelector('.back-to-top');
  const footerWrapper = document.querySelector('.footer__links-wrapper');

  if (backToTopButton && footerWrapper) {
    // on load
    helpers.addClassToBackToTopButton(backToTopButton, footerWrapper);
    // on scroll
    window.addEventListener('scroll', () => {
      helpers.addClassToBackToTopButton(backToTopButton, footerWrapper);
    });
  }
};

/**
* Controls the state of the category options
* @returns {void}
*/
const filterOptions = () => {
  const selectAllOption = document.querySelector('#selectAll');
  const categories = Object.values(document.querySelectorAll('input[name=categories]'));

  if (selectAllOption && categories) {
    console.dir(categories)
    // すべて (Select All) is the default state
    let isSelectAllSelected = true;

    // if すべて is selected, remove checked state for all categories
    if (isSelectAllSelected) {
      categories.map(cat => cat.checked = false);
    }

    // watch the onchange event of すべて option
    selectAllOption.addEventListener('change', () => {
      isSelectAllSelected = selectAllOption.checked;

      // if すべて is selected, remove checked state for all categories
      if (isSelectAllSelected) {
        categories.map(cat => cat.checked = false);
      }
    });

    categories.forEach(category => {
      // watch the onchange event of individual option
      category.addEventListener('change', () => {
        // if every individual category is selected, すべて will be checked
        const isEveryCategorySelected = categories.every(a => a.checked === true);
        const isCategorySelected = categories.some(a => a.checked === true);
        selectAllOption.checked = isEveryCategorySelected;

        // if すべて is selected, make sure select all is not checked
        if (isEveryCategorySelected) {
          selectAllOption.checked = false;
        }

        // if all categories are not selected, すべて will be selected as default
        if (!isCategorySelected) {
          selectAllOption.checked = true;
        }
      });
    });
  }
};

const openModal = () => {
  const buttons = document.querySelectorAll('.js-open-modal');

  if (buttons) {
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const { modal } = button.dataset;
        const offsetY = window.pageYOffset;
        const dialog = document.querySelector('#' + modal);

        dialog.scrollTo(0, 0);
        dialog.showModal();
        document.documentElement.style.scrollBehavior = 'initial';
        document.body.classList.add('modal-open');
        document.body.style.top = `-${offsetY}px`;
      });
    });
  }
};

const closeModal = () => {
  const buttons = document.querySelectorAll('.js-close-modal');

  if (buttons) {
    buttons.forEach(button => {
      button.addEventListener('click', () => {
        const { modal } = button.dataset;
        const offsetY = Math.abs(parseInt(document.body.style.top, 10));
        const dialog = document.querySelector('#' + modal);

        dialog.close();
        document.body.classList.remove('modal-open');
        document.body.style.top = 'initial';

        if (offsetY) {
          window.scrollTo(0, offsetY);
        }

        document.documentElement.style.scrollBehavior = 'smooth';
      });
    });
  }
};

export { fixedHeaderToTop, showBackToTopButton, filterOptions, openModal, closeModal }
